
.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .video {
        position: absolute;
        width: auto;
        height: 100%;
        top: 0;
        left: 0;
      }

    .videoThumb {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity 400ms ease 0ms;
      }
      
    .tiny {
        filter: blur(20px);
        transform: scale(1.1);
        transition: visibility 0ms ease 400ms;
    }
}