.container {
    ul {
        list-style: none;
        margin-top: 5px;
        li {
            margin-left: 15px;
            &:not(:last-child) {
                        margin-bottom: 5px;
                    }
        }

        
    }

    ul li:before {
        content: "\2022";
        color: #5D599E;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }

    a {
        text-decoration: underline;
        cursor: pointer;
    }

    table {
        background-color: white;
        border-spacing: 1px;
        color: black;
        border-collapse: collapse; 
        //table-layout: fixed;
    }

    table td {
        border: 1px #9894C6 solid;
    }

    td {
        padding: 5px 15px;
        vertical-align: top;
    }
}