body {
  margin: 0;
  overflow-x: auto;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #9591D9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.contentbox {
  margin: 0 auto;
  max-width: 1280px;
  padding: 50px 20px;
}

@media screen and (max-width: 767px){
  .contentbox {
    padding: 0 0 60px 0;
  }
}

.otnotice-language-dropdown-container {
  display: none;
}

.otnotice-menu {
  padding-left: 15px;
  margin-bottom: 30px;
}
.otnotice-menu li {
  line-height: 1.3;
}
.otnotice-menu li a {
  font-size: 12px;
}

.otnotice-menu,
#ot-sdk-btn-floating {
  display: none !important;
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
  background: none;
}